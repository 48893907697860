@font-face {
  font-family: "Barlow";
  src: url("/font/Barlow.ttf");
  src: url("/font/Barlow.ttf") format("embedded-opentype"),
    url("/font/Barlow.ttf") format("woff"),
    url("/font/Barlow.ttf") format("truetype"),
    url("/font/Barlow.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Barlow Condensed Reg";
  src: url("/font/Barlow\ Condensed\ Reg.ttf");
  src: url("/font/Barlow\ Condensed\ Reg.ttf") format("embedded-opentype"),
    url("/font/Barlow\ Condensed\ Reg.ttf") format("woff"),
    url("/font/Barlow\ Condensed\ Reg.ttf") format("truetype"),
    url("/font/Barlow\ Condensed\ Reg.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Barlow Condensed Bold";
  src: url("/font/Barlow\ Condensed\ Bold.ttf");
  src: url("/font/Barlow\ Condensed\ Bold.ttf") format("embedded-opentype"),
    url("/font/Barlow\ Condensed\ Bold.ttf") format("woff"),
    url("/font/Barlow\ Condensed\ Bold.ttf") format("truetype"),
    url("/font/Barlow\ Condensed\ Bold.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Bellefair";
  src: url("/font/Bellefair.ttf");
  src: url("/font/Bellefair.ttf") format("embedded-opentype"),
    url("/font/Bellefair.ttf") format("woff"),
    url("/font/Bellefair.ttf") format("truetype"),
    url("/font/Bellefair.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}
