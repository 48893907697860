html {
  font-size: 100%;
  box-sizing: border-box;
}

body {
  border: none;
  outline: none;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
}

*::selection {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-thumb {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a,
a:active,
a:visited {
  color: var(--white);
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
  line-height: 1;
}

/* 
Width   : 1440px
Height  : 900px
*/
/* 
Width   : 768px
Height  : 1024px
*/
/* 
Width   : 375px
Height  : 667px
 */
/* 640px - 1024px - 1400px */
/* 639px - 1023px - 1399px */
.crsr {
  position: fixed;
  top: -3.125rem;
  left: -3.125rem;
  transform: translate(-49.55%, -50.25%);
  mix-blend-mode: difference;
  pointer-events: none;
  z-index: 9999;
}
.crsr::before {
  content: "";
  width: 5.625rem;
  height: 5.625rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform: scale(0.15);
  background: var(--white);
  box-shadow: 0 0 1.25rem cyan, 0 0 3.75rem cyan, 0 0 6.25rem cyan;
  border: 0.0625rem solid cyan;
  animation: colors 7s infinite;
  transition: all 200ms;
  transition-property: content transform opacity border background;
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.crsr.brnd {
  mix-blend-mode: unset;
}
.crsr.brnd::before {
  content: "";
  transform: scale(0.85);
  opacity: 0.25;
  border: none;
  background: linear-gradient(45deg, black, blue);
}
@media screen and (max-width: 39.9375em) {
  .crsr.brnd::before {
    transform: scale(0.75);
  }
}

.crsr.hmbrgr {
  mix-blend-mode: unset;
}
.crsr.hmbrgr::before {
  content: "";
  transform: scale(0.85);
  opacity: 0.15;
  border: none;
  background: linear-gradient(45deg, black, blue);
}
@media screen and (max-width: 39.9375em) {
  .crsr.hmbrgr::before {
    transform: scale(0.75);
  }
}

.crsr.nvlnks {
  mix-blend-mode: unset;
}
.crsr.nvlnks::before {
  content: "";
  transform: scale(0.85);
  opacity: 0.15;
  border: none;
  background: linear-gradient(45deg, black, blue);
}

.crsr.bnnrdstntnlnks {
  mix-blend-mode: unset;
}
.crsr.bnnrdstntnlnks::before {
  content: "";
  transform: scale(0.85);
  opacity: 0.25;
  border: none;
  background: linear-gradient(45deg, black, blue);
}
@media screen and (max-width: 39.9375em) {
  .crsr.bnnrdstntnlnks::before {
    transform: scale(0.55);
  }
}

.crsr.bnnrcrwlnks {
  mix-blend-mode: unset;
}
.crsr.bnnrcrwlnks::before {
  content: "";
  transform: scale(0.55);
  opacity: 0.25;
  border: none;
  background: linear-gradient(45deg, black, blue);
}

.hmbrgr {
  display: none;
}
@media screen and (max-width: 39.9375em) {
  .hmbrgr {
    margin-right: -0.625rem;
    display: block;
  }
}
.hmbrgr__mn {
  background-color: transparent;
  cursor: grab !important;
  border: none;
  display: flex;
  padding: 0;
  outline: none;
  z-index: 1000;
}
.hmbrgr__line {
  fill: none;
  stroke: whitesmoke;
  stroke-width: 3;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.hmbrgr__line1 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
}
.hmbrgr__line2 {
  stroke-dasharray: 60 60;
  stroke-width: 3;
}
.hmbrgr__line3 {
  stroke-dasharray: 60 207;
  stroke-width: 3;
}
.shw .hmbrgr__line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
}
.shw .hmbrgr__line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 3;
}
.shw .hmbrgr__line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 3;
}

.prldr {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #252755;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  transition-delay: 1s;
  transition-delay: 1s;
}
.prldr .ldbr {
  width: 0%;
  height: 100%;
  max-height: 0.375rem;
  background: linear-gradient(45deg, #09e7e7, #252755);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  overflow: hidden;
}
.prldr figure {
  width: 37.5rem;
  height: 37.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fade 300ms;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  position: relative;
}
.prldr figure span {
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: lightcyan;
  text-align: center;
  letter-spacing: 0.1875rem;
  font-family: fantasy;
  font-size: 1rem;
}
.prldr figure img {
  height: 6.25rem;
  display: inline-block;
  opacity: 0.05;
}

@keyframes fade {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.pgldd .prldr {
  height: 0;
}
.pgldd .prldr .ldbr {
  max-height: 100%;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
}
.pgldd .prldr figure {
  transform: translateY(50px);
  opacity: 0;
}

.cmmndr__image {
  width: 35.5rem;
  padding-left: 1.25rem;
}

.spclst__image {
  width: 27.5rem;
  margin-left: -4.0625rem;
}

.plt__image {
  width: 34.6875rem;
}

.engnr__image {
  width: 38.473125rem;
}

.cstm__plt__pgntn {
  margin-top: 5.5rem !important;
}

.index {
  max-width: 100%;
  height: 100vh;
  background-image: url(/assets/home/background-home-desktop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (max-width: 63.9375em) {
  .index {
    background-image: url(/assets/home/background-home-tablet.jpg);
  }
}
@media screen and (max-width: 39.9375em) {
  .index {
    background-image: url(/assets/home/background-home-mobile.jpg);
  }
}

.dstntn {
  width: 100%;
  height: 100vh;
  background-image: url(/assets/destination/background-destination-desktop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (max-width: 63.9375em) {
  .dstntn {
    background-image: url(/assets/destination/background-destination-tablet.jpg);
  }
}
@media screen and (max-width: 39.9375em) {
  .dstntn {
    background-image: url(/assets/destination/background-destination-mobile.jpg);
  }
}

.crew {
  width: 100%;
  height: 100vh;
  background-image: url(/assets/crew/background-crew-desktop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (max-width: 63.9375em) {
  .crew {
    background-image: url(/assets/crew/background-crew-tablet.jpg);
  }
}
@media screen and (max-width: 39.9375em) {
  .crew {
    background-image: url(/assets/crew/background-crew-mobile.jpg);
  }
}

.technology {
  width: 100%;
  height: 100vh;
  background-image: url(/assets/technology/background-technology-desktop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
@media screen and (max-width: 63.9375em) {
  .technology {
    background-image: url(/assets/technology/background-technology-tablet.jpg);
  }
}
@media screen and (max-width: 39.9375em) {
  .technology {
    background-image: url(/assets/technology/background-technology-mobile.jpg);
  }
}

@font-face {
  font-family: "Barlow";
  src: url("/font/Barlow.ttf");
  src: url("/font/Barlow.ttf") format("embedded-opentype"), url("/font/Barlow.ttf") format("woff"), url("/font/Barlow.ttf") format("truetype"), url("/font/Barlow.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Barlow Condensed Reg";
  src: url("/font/Barlow Condensed Reg.ttf");
  src: url("/font/Barlow Condensed Reg.ttf") format("embedded-opentype"), url("/font/Barlow Condensed Reg.ttf") format("woff"), url("/font/Barlow Condensed Reg.ttf") format("truetype"), url("/font/Barlow Condensed Reg.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Barlow Condensed Bold";
  src: url("/font/Barlow Condensed Bold.ttf");
  src: url("/font/Barlow Condensed Bold.ttf") format("embedded-opentype"), url("/font/Barlow Condensed Bold.ttf") format("woff"), url("/font/Barlow Condensed Bold.ttf") format("truetype"), url("/font/Barlow Condensed Bold.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "Bellefair";
  src: url("/font/Bellefair.ttf");
  src: url("/font/Bellefair.ttf") format("embedded-opentype"), url("/font/Bellefair.ttf") format("woff"), url("/font/Bellefair.ttf") format("truetype"), url("/font/Bellefair.ttf") format("svg");
  font-style: normal;
  font-weight: normal;
}
/* crsr */
@keyframes colors {
  0% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(360deg);
  }
}
:root {
  --primary: hsl(231, 77%, 90%);
  --secondary: hsl(230, 35%, 7%);
  --white: hsl(0, 0%, 100%);
  --barlow: "Barlow";
  --barlow-condensed-reg: "Barlow Condensed Reg";
  --barlow-condensed-bold: "Barlow Condensed Bold";
  --bellefair: "Bellefair";
  --easeinback: cubic-bezier(0.225, 0.795, 0.55, 1.275);
}

#main .banner__left {
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 63.9375em) {
  #main .banner__left {
    height: 100%;
    align-items: flex-start !important;
    justify-content: center !important;
  }
}
#main .banner__left-content {
  margin-left: 5.9375rem;
  margin-bottom: 8.25rem;
}
@media screen and (max-width: 63.9375em) {
  #main .banner__left-content {
    margin-top: 12.625rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .banner__left-content {
    margin-top: 7.046875rem;
  }
}
#main .banner__left-content h2 {
  font-family: var(--barlow-condensed-reg);
  font-size: 1.75rem;
  letter-spacing: 0.2953125rem;
  margin-bottom: 2.1875rem;
  color: var(--primary) !important;
}
@media screen and (max-width: 63.9375em) {
  #main .banner__left-content h2 {
    font-size: 1.25rem;
    letter-spacing: 3.375px;
    text-align: center;
    margin-bottom: 1.875rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .banner__left-content h2 {
    font-size: 1rem;
    letter-spacing: 0.16875rem;
    line-height: 1.1875rem;
    margin-bottom: 1.5625rem;
  }
}
#main .banner__left-content h1 {
  font-family: var(--bellefair);
  font-size: 9.375rem;
  margin-bottom: 2.1875rem;
  color: var(--white) !important;
}
@media screen and (max-width: 63.9375em) {
  #main .banner__left-content h1 {
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .banner__left-content h1 {
    font-size: 5rem;
    text-align: center;
    margin-bottom: 1.6875rem;
  }
}
#main .banner__left-content p {
  font-family: var(--barlow);
  font-size: 1.125rem;
  max-width: 27.75rem;
  height: 8rem;
  line-height: 32px;
  color: var(--primary) !important;
}
@media screen and (max-width: 63.9375em) {
  #main .banner__left-content p {
    font-size: 1rem;
    text-align: center;
    line-height: 1.75rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .banner__left-content p {
    font-size: 0.9375rem;
    line-height: 1.5625rem;
    max-width: 20.4375rem;
  }
}
#main .banner__right {
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 63.9375em) {
  #main .banner__right {
    height: 100%;
    align-items: flex-start !important;
    justify-content: center !important;
  }
}
#main .banner__right-content {
  margin-bottom: 8.25rem;
}
@media screen and (max-width: 63.9375em) {
  #main .banner__right-content {
    margin-top: 8.75rem;
    margin-bottom: 0rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .banner__right-content {
    margin-top: 4.875rem;
  }
}
#main .banner__right-button {
  width: 17.125rem;
  height: 17.125rem;
  border-radius: 50%;
  margin-right: 5.9375rem;
  background: var(--white);
  z-index: 1;
}
@media screen and (max-width: 63.9375em) {
  #main .banner__right-button {
    width: 15.125rem;
    height: 15.125rem;
    margin-right: 0rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .banner__right-button {
    width: 9.375rem;
    height: 9.375rem;
  }
}
#main .banner__right-button a {
  position: relative;
  font-family: var(--bellefair);
  font-size: 2rem;
  letter-spacing: 2px;
  color: var(--secondary) !important;
  background: var(--white);
  z-index: 1;
}
@media screen and (max-width: 39.9375em) {
  #main .banner__right-button a {
    font-size: 1.25rem;
    letter-spacing: 0.078125rem;
  }
}
#main .banner__right-button a::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  mix-blend-mode: normal;
  opacity: 0.15;
  transition: all 200ms;
  transition-timing-function: var(--easeinback);
  z-index: -1;
}
#main .banner__right-button a:hover::after {
  width: 28.125rem;
  height: 28.125rem;
  background: var(--white);
}
@media screen and (max-width: 63.9375em) {
  #main .banner__right-button a:hover::after {
    width: 23.4375rem;
    height: 23.4375rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .banner__right-button a:hover::after {
    width: 13.125rem;
    height: 13.125rem;
  }
}

#main .hdr {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 2.5rem;
  z-index: 1000;
}
@media screen and (max-width: 63.9375em) {
  #main .hdr {
    padding-top: 0rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .hdr {
    padding: 1.5rem;
    padding-top: 1.375rem;
  }
}
#main .hdr__brand {
  position: relative;
}
@media screen and (min-width: 64em) {
  #main .hdr__brand {
    padding-left: 3.4375rem;
  }
}
@media screen and (max-width: 63.9375em) {
  #main .hdr__brand {
    padding-left: 2.4375rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__brand {
    padding-left: 0rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__brand img {
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
  }
}
#main .hdr__brand a {
  cursor: grab;
}
#main .hdr__brand a::after {
  content: "";
  position: absolute;
  top: 50%;
  left: calc(100% + 4.0625rem);
  width: 29.5625rem;
  height: 0.0625rem;
  pointer-events: none;
  background: var(--white);
  mix-blend-mode: normal;
  opacity: 0.25;
  z-index: 1000;
}
@media screen and (max-width: 63.9375em) {
  #main .hdr__brand a::after {
    display: none;
  }
}
#main .hdr__navbar {
  position: relative;
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__navbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 15.884375rem;
    height: 100vh;
    clip-path: inset(0 0 0 100%);
    transition: all 600ms;
    transition-property: clip-path;
    transition-timing-function: var(--easeinback);
  }
}
#main .hdr__navbar.opn {
  clip-path: inset(0 0 0 0);
}
#main .hdr__nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2.1875rem;
  padding-right: 10.234375rem;
  padding-bottom: 2.1875rem;
  padding-left: 7.5rem;
  margin: 0;
  overflow: hidden;
  z-index: 999;
}
@media screen and (max-width: 63.9375em) {
  #main .hdr__nav {
    padding-top: 2.1875rem;
    padding-right: 2.77875rem;
    padding-bottom: 2.1875rem;
    padding-left: 2.785625rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__nav {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    padding: 0rem;
    padding-top: 7.084375rem;
  }
}
#main .hdr__nav::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(5.09678125rem);
  z-index: -1;
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__nav::before {
    width: 100%;
    height: 100%;
  }
}
#main .hdr__items {
  list-style: none;
}
#main .hdr__items:not(:last-child) {
  margin-right: 3.125rem;
}
@media screen and (max-width: 63.9375em) {
  #main .hdr__items:not(:last-child) {
    margin-right: 2.3125rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__items:not(:last-child) {
    margin-right: 0rem;
  }
}
#main .hdr__items:nth-child(2) {
  margin-right: 3.25rem;
}
@media screen and (max-width: 63.9375em) {
  #main .hdr__items:nth-child(2) {
    margin-right: 2.625rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__items:nth-child(2) {
    margin-right: 0rem;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__items {
    position: relative;
    width: 15.875rem;
    margin-bottom: 1.75rem;
  }
}
#main .hdr__links {
  cursor: none;
  position: relative;
  font-family: var(--barlow-condensed-reg);
  font-size: 1rem;
  letter-spacing: 0.16875rem;
  text-transform: uppercase;
  color: var(--white) !important;
}
@media screen and (max-width: 63.9375em) {
  #main .hdr__links {
    font-size: 0.875rem;
    letter-spacing: 2.36px;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__links {
    position: unset;
    font-size: 1rem;
    letter-spacing: 0.16875rem;
    margin-left: 2rem;
  }
}
#main .hdr__links span {
  font-weight: bold;
  margin-right: 0.625rem;
}
@media screen and (max-width: 63.9375em) {
  #main .hdr__links span {
    display: none;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__links span {
    display: inline-block;
  }
}
#main .hdr__links::after {
  content: "";
  position: absolute;
  bottom: calc(0 - 2.296875rem);
  left: 0;
  width: 100%;
  height: 0.1875rem;
  opacity: 0.5;
  transform: scaleX(0);
  transform-origin: right;
  background: var(--white);
  transition: all 300ms;
  transition-property: transform;
  transition-timing-function: var(--easeinback);
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__links::after {
    top: 45.75%;
    right: calc(0% - 12.95px);
    bottom: unset;
    left: unset;
    transform: translateY(-50%);
    width: 1.9375rem;
    height: 0.25rem;
    transform: scaleX(100%);
    transform-origin: unset;
    transform: rotate(90deg);
    display: none;
  }
}
#main .hdr__links:hover::after {
  transform: scaleX(100%);
  transform-origin: left;
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__links:hover::after {
    transform: scaleX(0);
    transform-origin: unset;
  }
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__span {
    margin-right: 0.78125rem !important;
  }
}
#main .hdr__links[aria-current=page]::after {
  display: block;
  transform: scaleX(100%);
  background: var(--white);
  opacity: 1;
}
@media screen and (max-width: 39.9375em) {
  #main .hdr__links[aria-current=page]::after {
    transform: rotate(90deg);
  }
}

#main .banner-crew__left {
  padding-left: 1.5625rem;
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-crew__left {
    align-items: flex-start !important;
    justify-content: center !important;
    padding: 0 !important;
  }
}
#main .banner-crew__left-content {
  margin-bottom: 5.9375rem;
  margin-left: 4.375rem;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-crew__left-content {
    margin: 0 !important;
  }
}
#main .banner-crew__title h2 {
  font-family: var(--barlow-condensed-reg);
  font-size: 1.75rem;
  letter-spacing: 0.295rem;
  color: var(--white) !important;
}
#main .banner-crew__title h2 span {
  font-family: var(--barlow-condensed-bold);
  mix-blend-mode: normal;
  opacity: 0.25;
}
#main .banner-crew__wrapper {
  margin-top: 9.6875rem;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-crew__pos-title {
    text-align: center;
  }
}
#main .banner-crew__pos-title h1 {
  font-family: var(--bellefair);
  font-size: 2rem;
  color: var(--white) !important;
  mix-blend-mode: normal;
  opacity: 0.5;
}
#main .banner-crew__name h1 {
  font-family: var(--bellefair);
  font-size: 3.5rem;
  margin-top: 1.25rem;
  color: var(--white) !important;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-crew__desc {
    text-align: center;
  }
}
#main .banner-crew__desc p {
  max-width: 27.75rem;
  font-family: var(--barlow);
  font-size: 1.125rem;
  line-height: 1.8;
  padding-top: 0.625rem;
  color: var(--primary) !important;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-crew__desc p {
    max-width: 28.625rem;
    padding: 0;
    text-align: center;
  }
}
#main .banner-crew__pagination {
  margin-top: 7.5rem;
}
#main .banner-crew__round {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.25rem;
}
#main .banner-crew__pagination-links {
  width: 0.9375rem;
  height: 0.9375rem;
  border-radius: 50%;
  display: block;
  opacity: 0.17;
  background-color: var(--white);
  cursor: none;
}
#main .banner-crew__pagination-links:hover {
  opacity: 0.5;
}
#main .banner-crew__pagination-links[aria-current=page] {
  opacity: 1;
}
#main .banner-crew__right {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
#main .banner-crew__person {
  height: auto;
  overflow: hidden;
  display: block;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

#main .banner-dstntn__left {
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__left {
    height: 100%;
    align-items: flex-start !important;
    justify-content: center !important;
  }
}
#main .banner-dstntn__left-content {
  margin-left: 6.609375rem;
  margin-bottom: 6.875rem;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__left-content {
    padding-top: 13.75rem;
    margin-bottom: 0rem;
    margin-left: 0rem;
  }
}
#main .banner-dstntn__left-content h1 {
  font-family: var(--barlow-condensed-reg);
  font-size: 1.75rem;
  letter-spacing: 0.2953125rem;
  line-height: 2rem;
  padding-bottom: 6.25rem !important;
  color: var(--white) !important;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__left-content h1 {
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: 0.2109375rem;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 9.0625rem;
    margin-left: 2.4375rem;
    padding-bottom: 0rem !important;
  }
}
#main .banner-dstntn__left-content h1 span {
  font-family: var(--barlow-condensed-bold);
  letter-spacing: 0.2953125rem;
  opacity: 0.25;
  mix-blend-mode: normal;
  color: var(--white) !important;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__left-content h1 span {
    font-size: 1.25rem;
    line-height: 1.5rem;
    letter-spacing: 0.2109375rem;
    padding-right: 1.25rem !important;
  }
}
#main .banner-dstntn__image {
  width: 27.8125rem;
  height: 27.8125rem;
  margin-left: 3.75rem;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__image {
    width: 18.75rem;
    height: 18.75rem;
    margin-left: 0rem;
  }
}
#main .banner-dstntn__right {
  width: 100%;
  height: 100vh;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__right {
    height: 100%;
    align-items: flex-start !important;
    justify-content: center !important;
  }
}
#main .banner-dstntn__right-content {
  max-width: 27.8125rem;
  height: 29.5rem;
  margin-right: 6.5625rem;
  margin-bottom: 7.5rem;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__right-content {
    max-width: 35.8125rem;
    height: auto;
    margin-right: 0rem;
    margin-bottom: 0rem;
    margin-top: 3.125rem;
  }
}
#main .banner-dstntn__anchor {
  margin-bottom: 3rem;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__anchor {
    margin-bottom: 2.421875rem;
  }
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
#main .banner-dstntn__li:not(:last-child) {
  margin-right: 2.034375rem;
}
#main .banner-dstntn__links {
  font-family: var(--barlow-condensed-reg);
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: 0.16875rem;
  color: var(--primary) !important;
  position: relative;
}
#main .banner-dstntn__links::after {
  content: "";
  position: absolute;
  bottom: calc(0 - 12px);
  left: 0;
  width: 100%;
  height: 0.1875rem;
  opacity: 0.5;
  transform: scaleX(0);
  transform-origin: right;
  background: var(--white);
  transition: all 200ms;
  transition-property: transform;
  transition-timing-function: var(--easeinback);
}
#main .banner-dstntn__links:hover::after {
  transform: scaleX(100%);
  transform-origin: left;
}
#main .banner-dstntn__links[aria-current=page] {
  color: var(--white) !important;
}
#main .banner-dstntn__links[aria-current=page]::after {
  opacity: 1;
  transform: scaleX(100%);
  background: var(--white);
}
#main .banner-dstntn__title {
  width: 20.8125rem;
  height: 7.1875rem;
  margin: 0 0 0.9375rem;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__title {
    width: 100%;
    text-align: center;
    margin: -0.3125rem;
  }
}
#main .banner-dstntn__title h1 {
  font-family: var(--bellefair);
  font-size: 6.25rem;
  line-height: 7.1875rem;
  color: var(--white) !important;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__title h1 {
    font-size: 5rem;
  }
}
#main .banner-dstntn__desc {
  max-width: 27.75rem;
  height: 8rem;
  margin: 0 0 3.371875rem;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__desc {
    max-width: 35.8125rem;
    height: 5.25rem;
    text-align: center;
    margin: 0 0 3.125rem;
  }
}
#main .banner-dstntn__desc p {
  font-family: var(--barlow);
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 2rem;
  color: var(--primary) !important;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__desc p {
    font-size: 1rem;
    line-height: 1.75rem;
  }
}
#main .banner-dstntn__rule {
  width: 27.75rem;
  height: 0.0625rem;
  margin-bottom: 1.875rem;
  display: block;
  background: #383b4b;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__rule {
    width: 100%;
  }
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__info {
    text-align: center;
    justify-content: space-evenly;
  }
}
#main .banner-dstntn__info-left {
  margin-right: 4.0625rem !important;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__info-left {
    margin-right: 0rem !important;
  }
}
#main .banner-dstntn__info-left h4 {
  font-family: var(--barlow-condensed-reg);
  font-size: 0.875rem;
  letter-spacing: 2.3625px;
  margin-bottom: 1.125rem;
  color: var(--primary) !important;
}
#main .banner-dstntn__info-left h3 {
  font-family: var(--bellefair);
  font-size: 1.75rem;
  color: var(--white) !important;
}
@media screen and (max-width: 63.9375em) {
  #main .banner-dstntn__info-right {
    margin-left: 0rem !important;
  }
}
#main .banner-dstntn__info-right h4 {
  font-family: var(--barlow-condensed-reg);
  font-size: 0.875rem;
  letter-spacing: 2.3625px;
  margin-bottom: 1.125rem;
  color: var(--primary) !important;
}
#main .banner-dstntn__info-right h3 {
  font-family: var(--bellefair);
  font-size: 1.75rem;
  color: var(--white) !important;
}

#main .banner-tech__left-content {
  margin-top: 13.125rem;
  margin-left: 9.375rem;
}
#main .banner-tech__title h2 {
  font-family: var(--barlow-condensed-reg);
  font-size: 1.75rem;
  letter-spacing: 0.2953125rem;
  line-height: 2.125rem;
  color: var(--white) !important;
}
#main .banner-tech__title h2 span {
  font-family: var(--barlow-condensed-bold);
  mix-blend-mode: normal;
  opacity: 0.25;
}
#main .banner-tech__left-pagination {
  margin-top: 8.484375rem;
  margin-right: 3.125rem;
}
#main .banner-tech__left-info {
  padding-top: 8.484375rem;
}
#main .banner-tech__round {
  position: relative;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
  overflow: hidden;
}
#main .banner-tech__pagination-links {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.234375rem;
  font-family: var(--bellefair);
  font-size: 2rem;
  letter-spacing: 0.125rem;
  line-height: 2.3125rem;
  color: var(--white);
  border-radius: 50%;
  border: 0.0625rem solid var(--white);
}
#main .banner-tech__pagination-links[aria-current=page] {
  color: var(--secondary) !important;
  border: none;
  background: var(--white);
}
#main .banner-tech__text-content {
  width: 29.375rem;
  height: 18.9375rem;
}
#main .banner-tech__sub-title {
  width: 9.546875rem;
  height: 1.1875rem;
  margin-bottom: 0.75rem;
}
#main .banner-tech__sub-title h2 {
  font-family: var(--barlow-condensed-reg);
  font-size: 1rem;
  line-height: 1.1875rem;
  letter-spacing: 0.16875rem;
  color: var(--primary) !important;
}
#main .banner-tech__content-title {
  width: 29.375rem;
  height: 4rem;
  margin-bottom: 1.25rem;
}
#main .banner-tech__content-title h1 {
  font-family: var(--bellefair);
  font-size: 3.5rem;
  line-height: 4rem;
  color: var(--white) !important;
}
#main .banner-tech__desc {
  width: 27.75rem;
  height: 12rem;
}
#main .banner-tech__desc p {
  font-family: var(--barlow);
  font-size: 1.125rem;
  line-height: 2rem;
  color: var(--primary) !important;
}
#main .banner-tech__image {
  margin-top: 17rem;
}