@use "../utils" as *;

.hmbrgr {
  display: none;
  @include brkpnt-down(sm) {
    margin-right: rem(-10);
    display: block;
  }

  &__mn {
    background-color: transparent;
    cursor: grab !important;
    border: none;
    display: flex;
    padding: 0;
    outline: none;
    z-index: 1000;
  }

  &__line {
    fill: none;
    stroke: whitesmoke;
    stroke-width: 3;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  &__line1 {
    stroke-dasharray: 60 207;
    stroke-width: 3;
  }

  &__line2 {
    stroke-dasharray: 60 60;
    stroke-width: 3;
  }

  &__line3 {
    stroke-dasharray: 60 207;
    stroke-width: 3;
  }

  .shw & {
    &__line1 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 3;
    }
  }

  .shw & {
    &__line2 {
      stroke-dasharray: 1 60;
      stroke-dashoffset: -30;
      stroke-width: 3;
    }
  }

  .shw & {
    &__line3 {
      stroke-dasharray: 90 207;
      stroke-dashoffset: -134;
      stroke-width: 3;
    }
  }
}
