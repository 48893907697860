a,
a:active,
a:visited {
  color: var(--white);
  text-decoration: none !important;
}

h1,
h2,
h3,
h4,
p {
  margin: 0;
  padding: 0;
  line-height: 1;
}
