:root {
  --primary: hsl(231, 77%, 90%);
  --secondary: hsl(230, 35%, 7%);
  --white: hsl(0, 0%, 100%);

  --barlow: "Barlow";
  --barlow-condensed-reg: "Barlow Condensed Reg";
  --barlow-condensed-bold: "Barlow Condensed Bold";
  --bellefair: "Bellefair";

  --easeinback: cubic-bezier(0.225, 0.795, 0.55, 1.275);
}
