@use "../utils" as *;

.crsr {
  position: fixed;
  top: rem(-50);
  left: rem(-50);
  transform: translate(-49.55%, -50.25%);
  mix-blend-mode: difference;
  pointer-events: none;
  z-index: 9999;

  &::before {
    content: "";
    width: rem(90);
    height: rem(90);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transform: scale(0.15);
    background: var(--white);
    box-shadow: 0 0 rem(20) cyan, 0 0 rem(60) cyan, 0 0 rem(100) cyan;
    border: rem(1) solid cyan;
    animation: colors 7s infinite;
    transition: all 200ms;
    transition-property: content transform opacity border background;
    transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
  }
}

.crsr.brnd {
  mix-blend-mode: unset;

  &::before {
    content: "";
    transform: scale(0.85);
    opacity: 0.25;
    border: none;
    background: linear-gradient(45deg, black, blue);
    @include brkpnt-down(sm) {
      transform: scale(0.75);
    }
  }
}

.crsr.hmbrgr {
  mix-blend-mode: unset;

  &::before {
    content: "";
    transform: scale(0.85);
    opacity: 0.15;
    border: none;
    background: linear-gradient(45deg, black, blue);
    @include brkpnt-down(sm) {
      transform: scale(0.75);
    }
  }
}

.crsr.nvlnks {
  mix-blend-mode: unset;

  &::before {
    content: "";
    transform: scale(0.85);
    opacity: 0.15;
    border: none;
    background: linear-gradient(45deg, black, blue);
  }
}

.crsr.bnnrdstntnlnks {
  mix-blend-mode: unset;

  &::before {
    content: "";
    transform: scale(0.85);
    opacity: 0.25;
    border: none;
    background: linear-gradient(45deg, black, blue);
    @include brkpnt-down(sm) {
      transform: scale(0.55);
    }
  }
}

.crsr.bnnrcrwlnks {
  mix-blend-mode: unset;

  &::before {
    content: "";
    transform: scale(0.55);
    opacity: 0.25;
    border: none;
    background: linear-gradient(45deg, black, blue);
  }
}
