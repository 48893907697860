@use "../utils" as *;

.prldr {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: #252755;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  transition-duration: 1s;
  transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
  transition-delay: 1s;
  transition-delay: 1s;

  .ldbr {
    width: 0%;
    height: 100%;
    max-height: rem(6);
    background: linear-gradient(45deg, #09e7e7, #252755);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    overflow: hidden;
  }

  figure {
    width: rem(600);
    height: rem(600);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fade 300ms;
    transition-duration: 1s;
    transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
    position: relative;

    span {
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      color: lightcyan;
      text-align: center;
      letter-spacing: rem(3);
      font-family: fantasy;
      font-size: rem(16);
    }

    img {
      height: rem(100);
      display: inline-block;
      opacity: 0.05;
    }
  }
}

@keyframes fade {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.pgldd {
  .prldr {
    height: 0;

    .ldbr {
      max-height: 100%;
      transition-duration: 1s;
      transition-timing-function: cubic-bezier(0.86, 0, 0.09, 1);
    }

    figure {
      transform: translateY(50px);
      opacity: 0;
    }
  }
}

.cmmndr__image {
  width: 35.5rem;
  padding-left: 1.25rem;
}

.spclst__image {
  width: 27.5rem;
  margin-left: -4.0625rem;
}

.plt__image {
  width: 34.6875rem;
}

.engnr__image {
  width: 38.473125rem;
}

.cstm__plt__pgntn {
  margin-top: 5.5rem !important;
}

.index {
  max-width: 100%;
  height: 100vh;
  background-image: url(/assets/home/background-home-desktop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include brkpnt-down(md) {
    background-image: url(/assets/home/background-home-tablet.jpg);
  }

  @include brkpnt-down(sm) {
    background-image: url(/assets/home/background-home-mobile.jpg);
  }
}

.dstntn {
  width: 100%;
  height: 100vh;
  background-image: url(/assets/destination/background-destination-desktop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include brkpnt-down(md) {
    background-image: url(/assets/destination/background-destination-tablet.jpg);
  }

  @include brkpnt-down(sm) {
    background-image: url(/assets/destination/background-destination-mobile.jpg);
  }
}

.crew {
  width: 100%;
  height: 100vh;
  background-image: url(/assets/crew/background-crew-desktop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include brkpnt-down(md) {
    background-image: url(/assets/crew/background-crew-tablet.jpg);
  }

  @include brkpnt-down(sm) {
    background-image: url(/assets/crew/background-crew-mobile.jpg);
  }
}

.technology {
  width: 100%;
  height: 100vh;
  background-image: url(/assets/technology/background-technology-desktop.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  @include brkpnt-down(md) {
    background-image: url(/assets/technology/background-technology-tablet.jpg);
  }

  @include brkpnt-down(sm) {
    background-image: url(/assets/technology/background-technology-mobile.jpg);
  }
}
