@use "../utils" as *;

#main .banner {
  &__left {
    width: 100%;
    height: 100vh;

    @include brkpnt-down(md) {
      height: 100%;
      align-items: flex-start !important;
      justify-content: center !important;
    }
  }

  &__left-content {
    margin-left: rem(95);
    margin-bottom: rem(132);

    @include brkpnt-down(md) {
      margin-top: rem(202);
      margin-bottom: rem(0);
      margin-left: rem(0);
    }

    @include brkpnt-down(sm) {
      margin-top: rem(112.75);
    }

    h2 {
      font-family: var(--barlow-condensed-reg);
      font-size: rem(28);
      letter-spacing: rem(4.725);
      margin-bottom: rem(35);
      color: var(--primary) !important;

      @include brkpnt-down(md) {
        font-size: rem(20);
        letter-spacing: 3.375px;
        text-align: center;
        margin-bottom: rem(30);
      }

      @include brkpnt-down(sm) {
        font-size: rem(16);
        letter-spacing: rem(2.7);
        line-height: rem(19);
        margin-bottom: rem(25);
      }
    }

    h1 {
      font-family: var(--bellefair);
      font-size: rem(150);
      margin-bottom: rem(35);
      color: var(--white) !important;

      @include brkpnt-down(md) {
        margin-bottom: rem(20);
      }

      @include brkpnt-down(sm) {
        font-size: rem(80);
        text-align: center;
        margin-bottom: rem(27);
      }
    }

    p {
      font-family: var(--barlow);
      font-size: rem(18);
      max-width: rem(444);
      height: rem(128);
      line-height: 32px;
      color: var(--primary) !important;

      @include brkpnt-down(md) {
        font-size: rem(16);
        text-align: center;
        line-height: 1.75rem;
      }

      @include brkpnt-down(sm) {
        font-size: rem(15);
        line-height: rem(25);
        max-width: rem(327);
      }
    }
  }

  &__right {
    width: 100%;
    height: 100vh;

    @include brkpnt-down(md) {
      height: 100%;
      align-items: flex-start !important;
      justify-content: center !important;
    }
  }

  &__right-content {
    margin-bottom: rem(132);

    @include brkpnt-down(md) {
      margin-top: rem(140);
      margin-bottom: rem(0);
    }

    @include brkpnt-down(sm) {
      margin-top: rem(78);
    }
  }

  &__right-button {
    width: rem(274);
    height: rem(274);
    border-radius: 50%;
    margin-right: rem(95);
    background: var(--white);
    z-index: 1;

    @include brkpnt-down(md) {
      width: rem(242);
      height: rem(242);
      margin-right: rem(0);
    }

    @include brkpnt-down(sm) {
      width: rem(150);
      height: rem(150);
    }

    a {
      position: relative;
      font-family: var(--bellefair);
      font-size: rem(32);
      letter-spacing: 2px;
      color: var(--secondary) !important;
      background: var(--white);
      z-index: 1;

      @include brkpnt-down(sm) {
        font-size: rem(20);
        letter-spacing: rem(1.25);
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        mix-blend-mode: normal;
        opacity: 0.15;
        transition: all 200ms;
        transition-timing-function: var(--easeinback);
        z-index: -1;
      }

      &:hover::after {
        width: rem(450);
        height: rem(450);
        background: var(--white);

        @include brkpnt-down(md) {
          width: rem(375);
          height: rem(375);
        }

        @include brkpnt-down(sm) {
          width: rem(210);
          height: rem(210);
        }
      }
    }
  }
}
