// Desktop Size
/* 
Width   : 1440px
Height  : 900px
*/

// Tablet Size
/* 
Width   : 768px
Height  : 1024px
*/

// Mobile Size
/* 
Width   : 375px
Height  : 667px
 */

/* 640px - 1024px - 1400px */
$brkpnts-up: (
  "md": "40em",
  "lg": "64em",
  "xl": "87.5em",
);

/* 639px - 1023px - 1399px */
$brkpnts-down: (
  "sm": "39.9375em",
  "md": "63.9375em",
  "lg": "87.4375em",
);

@mixin brkpnt-up($sz) {
  @media screen and (min-width: map-get($brkpnts-up, $sz)) {
    @content;
  }
}

@mixin brkpnt-down($sz) {
  @media screen and (max-width: map-get($brkpnts-down, $sz)) {
    @content;
  }
}
