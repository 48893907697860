html {
  font-size: 100%;
  box-sizing: border-box;
}

body {
  border: none;
  outline: none;
  overflow-x: hidden;
}

* {
  margin: 0;
  padding: 0;
}

*::selection {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-track,
*::-webkit-scrollbar-thumb {
  display: none;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
